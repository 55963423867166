import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { IBrands, IBrandsData, ILOLMasterData } from './models';
import { useQuery } from '@apollo/client';
import { BRAND_QUERY, MASTERDATA_QUERY } from '../services/query';
import Loader from '../components/Loader';

export interface IAppContext {
  FgiReactTestVariable?: string;
  brandList?: IBrands[];
  masterData?: ILOLMasterData;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  loader: string[];
  setLoader: React.Dispatch<React.SetStateAction<string[]>>;
}
const defaultState = {} as IAppContext;

const AppContext = createContext<IAppContext>(defaultState);

const FgiReactTestVariable = process.env.REACT_APP_FGI_TEST_VARIABLE;

console.log('AuthProtectedRoutes ~~~~  FgiReactTestVariable', FgiReactTestVariable);

export const useAppContext = (): IAppContext => useContext(AppContext);

const AppContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [token, setToken] = useState('');
  const [loader, setLoader] = useState<string[]>([]);
  const [brandList, setBrandList] = useState<IBrands[]>();
  const [masterData, setMasterData] = useState<ILOLMasterData>();
  const [userName, setUserName] = useState(''); 
  
  // Helper function to manage the loader state
    const checkLoading = (isLoading: boolean, loaderName: string) => {
      setLoader((prevLoader) => {
        if (isLoading && !prevLoader.includes(loaderName)) {
          return [...prevLoader, loaderName];
        }
        if (!isLoading) {
          return prevLoader.filter((item) => item !== loaderName);
        }
        return prevLoader;
      });
    };
    const state: IAppContext = {
      FgiReactTestVariable, // Test variable
      brandList,   // The array of brands
      masterData,  // The master data object
      userName,    // The username string
      setUserName, // Function to update the username
      token,       // The authentication token
      setToken,    // Function to update the token
      loader,      // Array of loader states
      setLoader,   // Function to update the loader state
    };
  const roles = localStorage.getItem('roles');

  // const roles = "GG_FGI_Employee,GG_Forage_Genetics_Master"; // for local testing
  const rolesArray = roles?.split(',');

  // GraphQL Queries
  const { error: masterDataError, data: masterDatas, loading: masterDataLoading } = useQuery<ILOLMasterData>(MASTERDATA_QUERY);
  const { error: brandsDataError, data: brandsData, loading: brandsLoading } = useQuery<IBrandsData>(BRAND_QUERY, {
    variables: { roleNames: rolesArray },
  });



  // Effect to handle loading states
  useEffect(() => {
    checkLoading(masterDataLoading, 'masterDataLoading');
    checkLoading(brandsLoading, 'brandsLoading');
  }, [masterDataLoading, brandsLoading]);

  // Effect to set brandList and masterData once data is loaded
  useEffect(() => {
    if (brandsData) {
      setBrandList(brandsData?.lOLBrandsCollection.items.filter(a => a.roles.items.length > 0));
    }
    if (masterDatas) {
      setMasterData(masterDatas);
    }
  }, [brandsData, masterDatas]);
  // State to be passed to the context

  return (
    <AppContext.Provider value={state}>
      {loader && loader.length > 0 &&
        <Loader />
      }
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
